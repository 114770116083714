/** The default search radius */
export const defaultSearchRadius = 50;

/** The default search radius index (should point to defaultSearchRadius) */
export const defaultSearchRadiusIndex = 2;

/**
 * The coordinates of Petco Love HQ. Used as a fallback location if no
 * coordinates are provided.
 */
export const petcoLoveHQCoordinates = {
  latitude: 29.44241,
  longitude: -98.655,
};
