import { NextRequest, NextResponse } from 'next/server';

/** This constant is also used in the useFeatureFlags hook so need to be exported */
export const featureFlagDecisionKey = 'feature-flag-decisions';

/**
 * AbstractFeatureFlagMiddleware
 *
 * This class is an abstract class that is used to define the structure of a
 * feature flag middleware.
 *
 * @abstract
 * @class
 */
export default abstract class AbstractFeatureFlagMiddleware {
  /** The storage key for the feature flag decisions */
  readonly featureFlagDecisionKey = featureFlagDecisionKey;

  /** The storage key for the feature flag user ids */
  readonly featureFlagUserKey = 'feature-flag-user-id';

  /** How long the cookies should be active setting for one year */
  readonly featureFlagExpiration = 60 * 60 * 24 * 7 * 52;

  /**
   * Get the current enabled feature flags
   *
   * @param {NextRequest} req - The incoming request
   * @returns {NextResponse} - The feature flags that are enabled
   */
  abstract processFeatureFlagMiddleware(req: NextRequest): NextResponse;
}
