import { WebSocketState } from '@petcolove/lost--client--api-sdk/dist/abstract/service/interfaces/websocket-service';
import { BaseWebSocketService } from '@petcolove/lost--client--api-sdk/dist/concrete/sdks/services/websocket/base-websocket-service';
import { useEffect, useMemo, useState } from 'react';
import {
  IWebSocketConfig,
  IWebSocketServiceState,
  ServerMessageFrom,
} from './interfaces';

const defaultWebSocketConfig: IWebSocketConfig = {
  messageBufferSize: Infinity,
  disable: false,
};

/**
 * WebSocket integration hook.
 *
 * @template TWebSocketService
 * @param {() => TWebSocketService} createWebSocketService - WebSocket service
 *   factory for lazy initialization.
 * @param {IWebSocketConfig} configOverrides - Custom connection options.
 * @returns {IWebSocketServiceState<TWebSocketService>} - The processed messages
 *   and service instance.
 */
export function useWebSocketService<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TWebSocketService extends BaseWebSocketService<any, any>
>(
  createWebSocketService: () => TWebSocketService,
  configOverrides: IWebSocketConfig
): IWebSocketServiceState<TWebSocketService> {
  /** Extracted server message generic */
  type TServerMessage = ServerMessageFrom<TWebSocketService>;

  // Consolidated config object
  const config = {
    ...defaultWebSocketConfig,
    ...configOverrides,
  } as Required<IWebSocketConfig>;

  // React state <-> socket messages connection
  const [buffer, setBuffer] = useState<TServerMessage[]>([]);

  // React state <-> status change connection
  const [status, setStatus] = useState<WebSocketState>('void');

  // One-off lazy initialization of the service instance
  const service = useMemo(
    () => (config.disable ? null : createWebSocketService()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config.disable]
  );

  // Initialize (and cleanup) socket service connection
  useEffect(() => {
    if (!service) {
      return;
    }

    service.connect();

    const unsubscribe = service.addEventListener((message) => {
      setBuffer((current) =>
        [message, ...current].slice(0, config.messageBufferSize)
      );
    });

    /**
     * Void handler to refresh the socket connection status
     *
     * @returns {void}
     */
    const onStatusChange = () => setStatus(service.getStatus());

    /*
     * We don't really need to track the status changes since the service
     * already provides a function to check the status in the WebSocket
     * instance itself
     */
    service.connection?.addEventListener('open', onStatusChange);
    service.connection?.addEventListener('close', onStatusChange);
    service.connection?.addEventListener('error', onStatusChange);

    return () => {
      service.connection?.removeEventListener('open', onStatusChange);
      service.connection?.removeEventListener('close', onStatusChange);
      service.connection?.removeEventListener('error', onStatusChange);
      unsubscribe();
    };
  }, [config.messageBufferSize, service]);

  return useMemo(
    () => ({
      messages: {
        all: buffer,
        last: buffer.at(0),
      },
      status: status,
      service: service,
    }),
    [buffer, status, service]
  );
}
