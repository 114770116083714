import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import PersistentBanner from '@/components/molecules/PersistentBanner/PersistentBanner';
import { typeToFlow } from '@/lib/constants/constants/analytics';
import { defaultSearchRadius } from '@/lib/constants/constants/search';
import { InferredSpecies } from '@/lib/constants/types/pets';
import { useEventContext } from '@/lib/hooks/analytics/useEventContext';
import {
  createAccountBannerProps,
  PersistentBannerTypes,
  petSearchBannerProps,
  usePersistentBannerContext,
} from '@/lib/hooks/persistentBanner/PersistentBanner';
import { SpeciesTypes } from '@/lib/utils/analytics/petSearch';
import {
  IStartAddPetEventData,
  startAddPetEventHandler,
} from '@/lib/utils/analytics/startAddPet';
import { startPetSearchEventHandler } from '@/lib/utils/analytics/startPetSearch';
import appendAttributeIfExists from '@/lib/utils/helpers/ObjectsHelpers/AppendAttributeIfExists';
import capitalizeFirstLetter from '@/lib/utils/helpers/stringHelpers/capitalizeFirstLetter';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

/**
 * The href for the photo search link
 *
 * @constant {string}
 */
const photoSearchBaseHref = '/photo-search';

/**
 * The text for the photo search link
 *
 * @constant {string}
 */
export const searchClickText = 'Click to Search';

/**
 * The text for the create account link
 *
 * @constant {string}
 */
export const createAccountClickText = 'Create an account';

/**
 * Determine the color to pass to the banner
 *
 * @param {PersistentBannerTypes} type - The type of banner
 * @returns {string} - The class used to color the banner
 */
const bannerClasses = (type?: PersistentBannerTypes): string => {
  if (type === 'info') {
    return 'bg-info-200';
  } else {
    return 'bg-warning-200';
  }
};

/**
 * Sends the start add pet event
 *
 * @param {createAccountBannerProps} data - The data from the persistent banner
 */
export const sendStartAddPetEvent = (data: createAccountBannerProps): void => {
  const eventData: IStartAddPetEventData = {
    clickText: createAccountClickText,
    context: 'Sign Up Flow',
    petStatus: data.petReportType === 'found' ? 'Found' : 'Lost',
    listingSource: 'Consumer Pet Listing',
    species: data.species
      ? (capitalizeFirstLetter(data.species) as InferredSpecies)
      : undefined,
  };
  startAddPetEventHandler(eventData);
};

/**
 * Return the create account banner element
 *
 * @param {createAccountBannerProps} data - The data for the info banner
 * @returns {Element} - The info banner children
 */
const createAccountBanner = (data: createAccountBannerProps): JSX.Element => {
  return (
    <div data-testid="create-account-banner">
      <Link
        href={`/create-account/${data.petReportType}-${data.searchType}`}
        onClick={() => sendStartAddPetEvent(data)}
        className="text-neutral-100 font-petco text-body5 font-bold underline inline-block mr-1"
      >
        {createAccountClickText}
      </Link>
      <Paragraph
        size="body5"
        styling="bold"
        className="text-neutral-100 inline-block"
      >
        to report a pet.
      </Paragraph>
    </div>
  );
};

/**
 * The interface for the PersistentBannerHandler props
 *
 * @interface IPersistentBannerHandler
 */
export interface IPersistentBannerHandler {
  /**
   * The type of banner
   *
   * @memberof IPersistentBannerHandler
   * @member {PersistentBannerTypes} [type]
   */
  type?: PersistentBannerTypes;
  /**
   * The type of banner to display
   *
   * @memberof IPersistentBannerHandler
   * @member {createAccountBannerProps | petSearchBannerProps} [bannerProps]
   */
  bannerProps?: createAccountBannerProps | petSearchBannerProps;
}

/** @returns {React.FC} - The PersistentBannerHandler */
const PersistentBannerHandler: React.FC<IPersistentBannerHandler> = () => {
  const { type, bannerProps } = usePersistentBannerContext();
  const { clearData, updateData, setInitiatingComponent } = useEventContext();
  const [bannerChildrenState, setBannerChildrenState] = useState<
    JSX.Element | undefined
  >(undefined);
  const router = useRouter();

  /**
   * The function to store the analytics event data
   *
   * @param {petSearchBannerProps} data - The data for the pet search banner
   */
  const searchBannerAnalytics = (data: petSearchBannerProps): void => {
    const initiatingComponent = 'Search Persistent Banner / All Pages';
    setInitiatingComponent(initiatingComponent);
    startPetSearchEventHandler({
      clickText: searchClickText,
      component: initiatingComponent,
      flow: data.petReportType === 'lost' ? 'Found' : 'Lost',
    });
    clearData();
    updateData({
      clickText: searchClickText,
      petId: data.petId,
      petName: data.petName,
      petStatus: typeToFlow[data.petReportType],
      locationId: data.zipCode,
      species: capitalizeFirstLetter(data.petData.species) as SpeciesTypes,
    });
  };

  /**
   * Return the pet search banner element
   *
   * @param {petSearchBannerProps} data - The data for the pet search banner
   * @returns {Element} - The pet search banner children
   */
  const petSearchBanner = (data: petSearchBannerProps): JSX.Element => {
    /**
     * The query data for the photo search link
     *
     * @type {object}
     */
    const queryData = {
      species: data.petData.species,
      photoSearchSpecies: data.petData.species,
      latitude: data.petData.coordinates.latitude.toString(),
      longitude: data.petData.coordinates.longitude.toString(),
      searchType: data.petReportType === 'lost' ? 'found' : 'lost',
      imageObjectKey: data.petData.photo,
      searchRadius: String(defaultSearchRadius),
      ...appendAttributeIfExists('address', data.petData.address),
    };
    /**
     * The query string for the photo search link
     *
     * @type {URLSearchParams}
     */
    const query = new URLSearchParams(queryData);
    /**
     * The href for the photo search link
     *
     * @type {string}
     */
    const photoSearchHref = `${photoSearchBaseHref}?${query}`;

    return (
      <div data-testid="search-pet-banner">
        <Paragraph
          size="body5"
          styling="bold"
          className="text-neutral-100 inline-block"
          data-testid="persistent-banner-text"
        >
          You have a {data.petReportType} pet.
        </Paragraph>{' '}
        <Link
          href={photoSearchHref}
          className="text-neutral-100 font-petco text-body5 font-bold underline inline-block"
          onClick={() => searchBannerAnalytics(data)}
        >
          {searchClickText}
        </Link>
      </div>
    );
  };

  /**
   * This will give us the ability to hide the banner on certain pages
   *
   * @returns {boolean} - Whether or not to hide the banner
   */
  const hideBannerOnPages = useMemo((): boolean => {
    /**
     * WarningBannerHidePages These pages will hide the warning banner
     *
     * @type {string[]}
     */
    const warningBannerHidePages: string[] = [
      '/photo-search',
      '/search-results',
      '/microchip-search',
      '/create-account/lost/[...pageId]',
      '/create-account/found/[...pageId]',
      '/dash/match-results',
    ];

    const infoBannerHidePages: string[] = [
      '/photo-search',
      '/search-results',
      '/microchip-search',
      '/i-lost-a-pet',
      '/i-found-a-pet',
      '/redirecting',
      '/dash/match-results',
    ];
    if (type === 'info' && infoBannerHidePages.includes(router.pathname)) {
      return true;
    }

    if (
      type === 'warning' &&
      warningBannerHidePages.includes(router.pathname)
    ) {
      return true;
    }
    return false;
  }, [type, router.pathname]);

  /** Set the banner children state */
  useEffect(() => {
    if (type && bannerProps && !hideBannerOnPages) {
      if (type === 'info' && 'searchType' in bannerProps) {
        setBannerChildrenState(createAccountBanner(bannerProps));
      }
      if (type === 'warning' && 'petData' in bannerProps) {
        setBannerChildrenState(petSearchBanner(bannerProps));
      }
    } else {
      setBannerChildrenState(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, bannerProps, hideBannerOnPages]);

  return (
    <div data-testid="persistent-banner-handler">
      {bannerChildrenState && (
        <PersistentBanner classes={bannerClasses(type)}>
          {bannerChildrenState}
        </PersistentBanner>
      )}
    </div>
  );
};

export default PersistentBannerHandler;
