/**
 * IPersistentBanner Interface for the Persistent Banner component
 *
 * @interface IPersistentBanner
 */
export interface IPersistentBanner {
  /**
   * Children to be displayed in the banner
   *
   * @memberof IPersistentBanner
   * @member {React.ReactNode} children
   */
  children: React.ReactNode;
  /**
   * The optional classes for the component
   *
   * @memberof IPersistentBanner
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
}

/**
 * Persistent Banner Persistent Banner Component used to display user feedback
 * in a banner at the top of the page.
 *
 * @param {IPersistentBanner} props - The props for the Persistent Banner
 *   component
 * @returns {React.FC<IPersistentBanner>} Persistent Banner Component
 */
const PersistentBanner: React.FC<IPersistentBanner> = ({
  children,
  classes = '',
}: IPersistentBanner) => {
  return (
    <div
      data-testid="persistent-banner"
      className={`text-neutral-100 font-petco text-body5 justify-center py-[5px] flex sticky top-[60px] md:top-16 w-full z-[2] ${classes}`}
    >
      {children}
    </div>
  );
};

export default PersistentBanner;
