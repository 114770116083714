import { featureFlagDecisionKey } from '@/lib/utils/featureFlags/abstractFeatureFlagMiddleware';
import CookieStorage from '@/lib/utils/storage/cookie-storage';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/**
 * Feature Flag Storage
 *
 * @interface IFeatureFlagStorage
 */
interface IFeatureFlagStorage {
  /** Get the current enabled feature flags */
  featureFlags: string[] | null;
}

/**
 * Feature Flag Storage
 *
 * @class FeatureFlagStorage
 */
export class FeatureFlagStorage {
  /**
   * The feature flag storage
   *
   * @memberof FeatureFlagStorage
   * @member {CookieStorage} featureFlagStorage
   */
  readonly featureFlagStorage = new CookieStorage();

  /**
   * The feature flag storage key
   *
   * @memberof FeatureFlagStorage
   * @member {string} featureFlagStorageKey
   */
  readonly featureFlagStorageKey = featureFlagDecisionKey;

  /**
   * Get the current enabled feature flags
   *
   * @returns {string[] | null} - The feature flags that are enabled
   */
  public get = (): string[] | null => {
    const flags = this.featureFlagStorage.get(this.featureFlagStorageKey) as {
      // eslint-disable-next-line jsdoc/require-jsdoc
      decisions: string;
    } | null;
    return flags && flags?.decisions ? flags.decisions.split(',') : null;
  };
}

/**
 * Hook to get current enabled feature flags from the cookie storage and update
 * the state when the router changes
 *
 * @returns {IFeatureFlagStorage} - The feature flags that are enabled
 */
const useFeatureFlags = (): IFeatureFlagStorage => {
  const router = useRouter();
  const [featureFlags, setFeatureFlags] = useState<string[] | null>(null);
  const featureFlagStorage = new FeatureFlagStorage();

  useEffect(() => {
    const flags = featureFlagStorage.get();
    setFeatureFlags(flags);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return { featureFlags };
};

export default useFeatureFlags;
