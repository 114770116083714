import ButtonWithIcon from '@/components/molecules/ButtonWithIcon/ButtonWithIcon';
import { LinkType } from '@/components/organisms/Navbar/Navbar';
import { cva } from 'cva';
import Link from 'next/link';
import { Fragment } from 'react';

/**
 * INavTopBar - The props for the NavTopBar component
 *
 * @interface INavTopBar
 */
export interface INavTopBar {
  /**
   * The Links to display across the top navigation.
   *
   * @memberof INavTopBar
   * @member {LinkType[]} links
   */
  links: LinkType[];
}

const navTopBarLinkClasses = cva(
  'text-overline font-petco hover:text-base-300 flex',
  {
    variants: {
      state: {
        active: 'text-base-400 font-bold',
        base: '',
      },
    },
  }
);

/**
 * Nav Top Bar Top Navigation Bar Component for displaying the navigation links
 * across the top of the screen.
 *
 * @param {INavTopBar} props - The props for the component.
 * @returns {React.FC<INavTopBar>} Nav Top Bar Component
 */
const NavTopBar: React.FC<INavTopBar> = ({ links }: INavTopBar) => {
  return (
    <div
      className="bg-neutral-200 py-2.5 px-10 text-center hidden md:flex items-center justify-center space-x-4 h-10"
      data-testid="nav-top-bar"
    >
      {links.map((item, index, totalItems) => {
        const isLast = index === totalItems.length - 1;
        return (
          <Fragment key={item.name}>
            <div data-testid="nav-top-bar-link">
              <Link
                href={item.url}
                className={navTopBarLinkClasses({
                  state: item.active ? 'active' : 'base',
                })}
                target={item.target}
              >
                {item.icon ? (
                  <ButtonWithIcon icon={item.icon} classes={item.buttonClasses}>
                    {item.name}
                  </ButtonWithIcon>
                ) : (
                  item.name
                )}
              </Link>
            </div>
            {!isLast && (
              <div>
                <span className={navTopBarLinkClasses({ state: 'base' })}>
                  |
                </span>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default NavTopBar;
