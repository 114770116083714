import { flagShelterMultiUsers } from '@/lib/constants/constants/featureFlags';
import useFeatureFlags from './useFeatureFlags';

/**
 * Hook to get the shelter multi user feature flag status.
 *
 * @returns {object} - The shelter multi user flag
 */
export function useShelterMultiUserFlag() {
  const { featureFlags } = useFeatureFlags();

  return {
    isFlagEnabled: featureFlags?.includes(flagShelterMultiUsers),
  };
}
