import Heading from '@/components/atoms/Heading/Heading';
import Icon from '@/components/atoms/Icon/Icon';
import ButtonWithIcon from '@/components/molecules/ButtonWithIcon/ButtonWithIcon';
import { LinkType } from '@/components/organisms/Navbar/Navbar';
import Link from 'next/link';
import { useState } from 'react';

/**
 * INavSideBar Interface for the Nav Side Bar Component
 *
 * @interface INavSideBar
 */
export interface INavSideBar {
  /**
   * The Main Links for the NavSideBar
   *
   * @memberof INavSideBar
   * @member {LinkType[]} mainLinks
   */
  mainLinks: LinkType[];
  /**
   * The Lower Links for the NavSideBar
   *
   * @memberof INavSideBar
   * @member {LinkType[]} bottomLinks
   */
  bottomLinks: LinkType[];
  /**
   * The optional classes for the component.
   *
   * @memberof INavSideBar
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
}

/**
 * Nav Side Bar Nav Side Bar Component for displaying the navigation links on
 * the left side of the screen.
 *
 * @param {INavSideBar} props - The props for the component.
 * @returns {React.FC<INavSideBar>} Nav Side Bar Component
 */
const NavSideBar: React.FC<INavSideBar> = ({
  mainLinks,
  bottomLinks,
  classes = '',
}: INavSideBar) => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div data-testid="nav-side-bar" className={classes}>
      <button
        data-collapse-toggle="navbar-default"
        data-testid="nav-side-bar-button"
        type="button"
        onClick={() => setShowSidebar(!showSidebar)}
        className="inline-flex items-center mr-[5px] text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-default"
        aria-expanded="false"
      >
        <span className="sr-only">Open Main Menu</span>
        <Icon icon="menu" colorShade={800} colorType="neutral" size={24} />
      </button>
      <div
        className={`top-0 left-0 bg-neutral-100 fixed transition-all duration-500 h-full z-40 ${
          showSidebar ? 'translate-x-0 w-[100vw]' : '-translate-x-full'
        }`}
      >
        {showSidebar && (
          <div className="p-5 relative" data-testid="nav-side-bar-drawer">
            <span className="font-petco text-overline font-bold uppercase text-base-300">
              Menu
            </span>
            <button
              data-collapse-toggle="navbar-default"
              data-testid="nav-side-bar-close-button"
              type="button"
              onClick={() => setShowSidebar(!showSidebar)}
              className="absolute top-6 right-6"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span className="sr-only">Close Main Menu</span>
              <Icon icon="close" colorShade={400} colorType="base" size={24} />
            </button>
            <ul className="mt-[23px]">
              {mainLinks.map((link) => (
                <li
                  className="mb-3.5"
                  key={link.name}
                  onClick={() => setShowSidebar(!showSidebar)}
                  data-testid="nav-side-bar-main-link"
                >
                  <Link href={link.url}>
                    <Heading size="h3">{link.name}</Heading>
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="mt-8">
              <li className="font-petco font-bold text-body4 mb-3">
                Our Family
              </li>
              {bottomLinks.map((link) => (
                <li
                  key={link.name}
                  className={`font-petco mb-3 ${
                    link.classes ? link.classes : ''
                  } text-body4`}
                >
                  <a
                    href={link.url}
                    target={link.target}
                    rel="noopener noreferrer"
                  >
                    {link.icon ? (
                      <ButtonWithIcon
                        icon={link.icon}
                        classes={link.buttonClasses}
                      >
                        {link.name}
                      </ButtonWithIcon>
                    ) : (
                      link.name
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavSideBar;
