import CdnImage from '@/components/atoms/CdnImage/CdnImage';
import Link from 'next/link';

/**
 * ILostLogo Interface for Lost Logo component.
 *
 * @interface ILostLogo
 */
export interface ILostLogo {
  /**
   * The optional classes for the component.
   *
   * @memberof ILostLogo
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
  /**
   * Whether to load the image immediately, used if logo is in the LCP.
   *
   * @memberof ILostLogo
   * @default false
   * @member {boolean} [priority]
   */
  priority?: boolean;
  /**
   * The variant for the logo.
   *
   * @memberof ILostLogo
   * @member {'standard' | 'small'} variant
   */
  variant: 'standard' | 'small';
  /**
   * The alt text for the logo.
   *
   * @memberof ILostLogo
   * @default 'Petco Love Lost Logo'
   * @member {string} [alt]
   */
  alt?: string;
}

/**
 * Logo Size Map This map is used to map the size prop of the Logo Image.
 *
 * @constant
 */
const sizes = {
  standard: { width: 253, height: 56 },
  small: { width: 246, height: 44 },
};

/**
 * Logo Variant Map This map is used to map the different versions of the logo.
 *
 * @constant
 */
const variantLogo = {
  standard:
    process.env.NEXT_PUBLIC_CDN_BASE_URL +
    '/assets/lost/lost-logo-standard.svg',
  small:
    process.env.NEXT_PUBLIC_CDN_BASE_URL + '/assets/lost/lost-logo-small.svg',
};

/**
 * Lost Logo Used to display the Petco Love Lost logo. This is a wrapper around
 * the Next.js Image component.
 *
 * @param {ILostLogo} props - The props for the LostLogo component
 * @returns {React.FC<ILostLogo>} LostLogo Component
 */
const LostLogo: React.FC<ILostLogo> = ({
  priority = false,
  classes = '',
  alt = 'Petco Love Lost Logo',
  variant,
}: ILostLogo) => {
  const dataTestId = `lost-logo-${variant}`;
  return (
    <Link
      href="/"
      className="inline-flex focus:outline-none focus-visible:ring-solid focus-visible:ring-[1px] focus-visible:ring-focus-400 rounded"
    >
      <CdnImage
        src={variantLogo[variant]}
        priority={priority}
        width={sizes[variant].width}
        height={sizes[variant].height}
        layout="intrinsic"
        alt={alt}
        data-testid={dataTestId}
        className={classes}
      />
    </Link>
  );
};

export default LostLogo;
