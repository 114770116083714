import { unknownString } from '@/lib/constants/constants/analytics';
import { NextApiRequest } from 'next';

/**
 * This will get the gtag client id from cookie value
 *
 * @returns {string} - The gtag client id or not applicable if not found
 */
export const getClientIdFromCookie = (): string => {
  // Ensure this runs only in the browser
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    console.error('This function should only be ran in a browser environment');
    throw new Error(
      'This function should only be ran in a browser environment'
    );
  }

  const gaCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('_ga='));

  if (!gaCookie) {
    console.error('_ga token not found');
    return unknownString;
  }

  const gaCookieValue = gaCookie.split('=')[1];
  // Extract the last two segments of the cookie value
  const clientId = gaCookieValue.split('.').slice(-2).join('.');
  return clientId;
};

/**
 * Get the Client ID Token From Cookie SSR
 *
 * @param {NextApiRequest} request - The next api request
 * @returns {string} - The client id token
 */
export const getClientIdTokenFromCookieSSR = async (
  request: NextApiRequest
): Promise<string> => {
  try {
    const cookie = request.cookies.get('_ga')?.value;
    if (!cookie) {
      return unknownString;
    }
    const clientId = cookie.split('.').slice(-2).join('.');
    return clientId;
  } catch (error) {
    return unknownString;
  }
};
