import { IMeDto } from '@petcolove/lost--client--api-sdk/dist/concrete/sdks/services/auth/dto';

/**
 * Check if the user is an admin of the shelter
 *
 * @param {IMeDto | null} me - The me object
 * @param {number} selectedShelterId - The selected shelter id
 * @returns {boolean} - Whether the user is an admin of the shelter
 */
export function getIsShelterAdmin(
  me?: IMeDto | null,
  selectedShelterId?: number
) {
  const awos = me?.awos;

  const matchingAwo = awos?.find((awo) => awo?.id === selectedShelterId);

  const role = matchingAwo?.role;

  if (role && typeof role === 'string') {
    return role.toLowerCase() === 'admin';
  }

  return false;
}
